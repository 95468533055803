export const imports = {
  'src/__docs__/compatibility.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-compatibility" */ 'src/__docs__/compatibility.mdx'
    ),
  'src/__docs__/docs-settings.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-docs-settings" */ 'src/__docs__/docs-settings.mdx'
    ),
  'src/__docs__/getting-started.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-getting-started" */ 'src/__docs__/getting-started.mdx'
    ),
  'src/__docs__/introduction.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-introduction" */ 'src/__docs__/introduction.mdx'
    ),
  'src/__docs__/styling-and-theming.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-styling-and-theming" */ 'src/__docs__/styling-and-theming.mdx'
    ),
  'src/__docs__/translating.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-translating" */ 'src/__docs__/translating.mdx'
    ),
  'src/components/checkbox/__docs__/checkbox.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-checkbox-docs-checkbox" */ 'src/components/checkbox/__docs__/checkbox.mdx'
    ),
  'src/components/layoutButton/__docs__/layoutButton.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-layout-button-docs-layout-button" */ 'src/components/layoutButton/__docs__/layoutButton.mdx'
    ),
  'src/components/measurementTable/__docs__/measurementTable.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-measurement-table-docs-measurement-table" */ 'src/components/measurementTable/__docs__/measurementTable.mdx'
    ),
  'src/components/cineDialog/__docs__/cineDialog.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-cine-dialog-docs-cine-dialog" */ 'src/components/cineDialog/__docs__/cineDialog.mdx'
    ),
  'src/components/quickSwitch/__docs__/quickSwitch.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-quick-switch-docs-quick-switch" */ 'src/components/quickSwitch/__docs__/quickSwitch.mdx'
    ),
  'src/components/overlayTrigger/__docs__/OverlayTrigger.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-overlay-trigger-docs-overlay-trigger" */ 'src/components/overlayTrigger/__docs__/OverlayTrigger.mdx'
    ),
  'src/components/radioButtonList/__docs__/radioButtonList.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-radio-button-list-docs-radio-button-list" */ 'src/components/radioButtonList/__docs__/radioButtonList.mdx'
    ),
  'src/components/roundedButtonGroup/__docs__/roundedButtonGroup.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-rounded-button-group-docs-rounded-button-group" */ 'src/components/roundedButtonGroup/__docs__/roundedButtonGroup.mdx'
    ),
  'src/components/simpleDialog/__docs__/simpleDialog.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-simple-dialog-docs-simple-dialog" */ 'src/components/simpleDialog/__docs__/simpleDialog.mdx'
    ),
  'src/components/studyBrowser/__docs__/studyBrowser.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-study-browser-docs-study-browser" */ 'src/components/studyBrowser/__docs__/studyBrowser.mdx'
    ),
  'src/components/selectTree/__docs__/selectTree.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-select-tree-docs-select-tree" */ 'src/components/selectTree/__docs__/selectTree.mdx'
    ),
  'src/components/studyList/__docs__/studyList.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-study-list-docs-study-list" */ 'src/components/studyList/__docs__/studyList.mdx'
    ),
  'src/components/toolbarSection/__docs__/toolbarSection.docs.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-toolbar-section-docs-toolbar-section-docs" */ 'src/components/toolbarSection/__docs__/toolbarSection.docs.mdx'
    ),
  'src/components/tableList/__docs__/tableList.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-table-list-docs-table-list" */ 'src/components/tableList/__docs__/tableList.mdx'
    ),
  'src/components/userPreferencesModal/__docs__/about.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-user-preferences-modal-docs-about" */ 'src/components/userPreferencesModal/__docs__/about.mdx'
    ),
  'src/components/userPreferencesModal/__docs__/userPreferences.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-user-preferences-modal-docs-user-preferences" */ 'src/components/userPreferencesModal/__docs__/userPreferences.mdx'
    ),
  'src/elements/Icon/__docs__/icon.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-elements-icon-docs-icon" */ 'src/elements/Icon/__docs__/icon.mdx'
    ),
  'src/elements/form/__docs__/dropdownMenu.doc.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-elements-form-docs-dropdown-menu-doc" */ 'src/elements/form/__docs__/dropdownMenu.doc.mdx'
    ),
  'src/elements/form/__docs__/label.doc.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-elements-form-docs-label-doc" */ 'src/elements/form/__docs__/label.doc.mdx'
    ),
  'src/elements/form/__docs__/range.doc.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-elements-form-docs-range-doc" */ 'src/elements/form/__docs__/range.doc.mdx'
    ),
  'src/elements/form/__docs__/select.docs.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-elements-form-docs-select-docs" */ 'src/elements/form/__docs__/select.docs.mdx'
    ),
  'src/elements/form/__docs__/textArea.doc.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-elements-form-docs-text-area-doc" */ 'src/elements/form/__docs__/textArea.doc.mdx'
    ),
  'src/elements/form/__docs__/textInput.docs.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-elements-form-docs-text-input-docs" */ 'src/elements/form/__docs__/textInput.docs.mdx'
    ),
}
